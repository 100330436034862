<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Aggiungi nuovo utente
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Nome -->
          <validation-provider
            #default="validationContext"
            name="Nome"
            rules="required"
          >
            <b-form-group
              label="Nome"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.fullName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Mario Rossi"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <!--
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Username"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="userData.username"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        -->
          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="Telefono"
          >
            <b-form-group
              label="Telefono"
              label-for="contact"
            >
              <b-form-input
                id="Telefono"
                v-model="userData.contact"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company
          <validation-provider
            #default="validationContext"
            name="Company"
          >
            <b-form-group
              label="Company"
              label-for="company"
            >
              <b-form-input
                id="company"
                v-model="userData.company"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->
          <!-- Company -->


          <!-- Sales Person -->
          <validation-provider
            v-if="userData.role == 'agent'"
            #default="validationContext"
            name="Sales Person"
          >
            <b-form-group
              label="Sales Person"
              label-for="sales-person"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.slpcode"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="true"
                :options="salesPersons.some(x => x.Email == (userData.email || x.Email))?salesPersons.filter(x => x.Email == (userData.email || x.Email)):salesPersons"
                label="SlpName"
                index="SlpCode"
                input-id="sales-person"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Employee -->
          <validation-provider
            v-if="userData.role == 'agent'"
            #default="validationContext"
            name="Employee"
          >
            <b-form-group
              label="Employee"
              label-for="employee"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.empID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="true"
                :options="employees.some(x => x.eMail == (userData.email || x.eMail))?employees.filter(x => x.eMail == (userData.email || x.eMail)):employees"
                label="fullName"
                index="empID"
                :reduce=" x => x.empID"
                input-id="employee"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Plan
          <validation-provider
            #default="validationContext"
            name="Plan"
            rules="required"
          >
            <b-form-group
              label="Plan"
              label-for="plan"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.currentPlan"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="planOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="plan"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          -->
          <!-- Form Actions -->
          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="Ruolo"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salva
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annulla
            </b-button>
          </div>

          <div
            class="d-flex mt-2"
          >
            <b-alert
              variant="danger"
              :show="errori"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                Si è verificato un errore durante la creazione dell'utente</div></b-alert>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import Vue from 'vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BAlert,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import agencyStoreModule from '../../dealer/Agency/agencyStoreModule'

export default {

  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BAlert,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    roleOptions: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    roles: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    salesPersons: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    employees: {
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,

    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      CompanyID: '',
      country: '',
      contact: '',
    }

    const errori = ref(false)
    const slpOpt = ref([])
    const companies = ref([])
    const AGENCY_DEALER_STORE_MODULE_NAME = 'app-agency'
    if (!store.hasModule(AGENCY_DEALER_STORE_MODULE_NAME)) store.registerModule(AGENCY_DEALER_STORE_MODULE_NAME, agencyStoreModule)
    onUnmounted(() => {
      if (store.hasModule(AGENCY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(AGENCY_DEALER_STORE_MODULE_NAME)
    })
    onMounted(() => {
      store.dispatch(`${AGENCY_DEALER_STORE_MODULE_NAME}/fetchAgencies`, { })
        .then(res => {
          console.log(res.data)
          companies.value = res.data.agencies
        })
        .catch(error => {
          if (error.response.status === 404) {
            companies.value = undefined
          }
        })
    })
    // slpOpt.value = props.salesPersons.filter(f => f.CardType == val).map(x => x.SlpName)
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    // console.log(props.roleOptions)
    const loggedUser = getUserData()
    if (loggedUser.role != 'admin') {
      userData.value.CompanyID = loggedUser.CompanyID
      blankUserData.CompanyID = loggedUser.CompanyID
    }
    // else if (loggedUser.role == 'company') {
    //   props.roleOptions = props.roleOptions.filter(item => item.Value !== 'admin' || item.Value !== 'company')
    // } else if (loggedUser.role == 'admin') {
    //   props.roleOptions = props.roleOptions.filter(item => item.Value !== 'admin' || item.Value !== 'company')
    // }
    const onSubmit = () => {
      console.log(props.roles.roles[0])
      console.log(userData.value.role)
      const permissions = props.roles.roles.filter(x => x.name == userData.value.role)[0]
      console.log(permissions)
      store.dispatch('app-user/addUser', {
        userData: userData.value,
        permissions,
      })
        .then(res => {
          console.log(res)
          if (res.data.result.ResultCode == 0) {
            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
          } else {
            Vue.prototype.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.result.ResultMessage,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        }).catch(error => {
          console.log(error)
          errori.value = true
          if (error.response.status === 550) {
            Vue.prototype.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.ResultMessage,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
          setTimeout(() => {
            try {
              errori.value = false
            } catch (e) { console.log(e) }
          }, 6000)
        // .then(prop => {
        //   emit('refetch-data')
        //   emit('update:is-add-new-user-sidebar-active', false)
        // }).catch(err => {
        //   console.log(err)
        //   errori.value = true
        //   setTimeout(() => {
        //     try {
        //       errori.value = false
        //     } catch (e) {}
        //   }, 6000)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      loggedUser,
      companies,
      refFormObserver,
      getValidationState,
      resetForm,
      slpOpt,
      errori,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
